<template>
	<div class="mb-4 px-6 rounded-xl shadow-xl bg-color-white py-6 flex flex-col justify-center">
		<h4 class="font-semibold mb-7 text-center">Dein Lebenslauf wird jetzt ausgelesen...</h4>
		<div class="flex relative justify-center mb-4">
			<CircularProgress :percentage="percentage" :color="progressColor" />
			<div class="w-[140px] h-[140px] absolute flex justify-center items-center">
				<p class="text-color-main font-bold text-3xl mb-0">{{ percentage }}%</p>
			</div>
		</div>
		<div v-for="(section, index) in cvSections" :key="section">
			<p class="flex mb-2">
				<HokIcon
					v-if="checkedSections[index] || percentage === 100"
					name="icon:swipe-right"
					color="main"
				/>
				<HokIcon v-else name="icon:checkmark-2" color="main" />
				<span
					class="ml-4"
					:class="
						checkedSections[index] || percentage === 100
							? 'text-color-text'
							: 'text-color-grey-medium'
					"
					>{{ section }}</span
				>
			</p>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import CircularProgress from '@hokify/pwa-core-nuxt3/components/profile/CircularProgress.vue';
import type { PropType } from 'vue';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'StageCv',
	components: {
		CircularProgress
	},
	emits: ['end-go-to', 'parsing-done'],
	data() {
		const checkedSections: boolean[] = [];
		return {
			sequence: [2, 3, 2, 2, 1], // for calculating percentage intervals
			cvSections: [
				'Lebenslauf',
				'Kontaktdaten',
				'Erfahrungen',
				'Ausbildungen',
				'Sprachkenntnisse',
				'Skills',
				'Führerscheine'
			],
			progressColor: { startColor: '#0FB1AF', stopColor: '#BD2297' },
			percentage: 0,
			checkedSections,
			cvParsingDone: false
		};
	},

	mounted() {
		if (this.cvParsed) {
			this.updateProgress();
		}
	},
	methods: {
		async saveCv() {
			try {
				await this.userProfileStore.verifyParsedCv({ isVerified: true });
				this.$emit('end-go-to');
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		updateProgress() {
			for (const index of this.checkmarkThresholds.keys()) {
				setTimeout(() => {
					this.checkedSections.push(true);
				}, this.checkmarkIntervals[index] * 1000);
			}
			for (const index of this.percentageThresholds.keys()) {
				setTimeout(() => {
					if (!this.cvLoading) {
						this.percentage = 100;
						setTimeout(() => {
							this.cvParsingDone = true;
							this.$emit('parsing-done');
						}, 1500);
					} else {
						this.percentage += Math.round(
							(this.percentageThresholds[index] / this.avgParsingTime) * 100
						);
						if (this.percentage >= 100) {
							this.percentage = 99;
						}
					}
				}, this.percentageIntervals[index] * 1000);
			}
		},
		calculateIntervals(thresholds: number[]): number[] {
			const intervals: number[] = [];
			let oldThreshold = 0;
			let currentThreshold = 0;

			thresholds.forEach((threshold: number) => {
				currentThreshold = threshold + oldThreshold;
				intervals.push(currentThreshold);
				oldThreshold = currentThreshold;
			});
			return intervals;
		},
		onCvLoadingStateChange(newVal: boolean) {
			if (!newVal) {
				this.percentage = 100;
				setTimeout(() => {
					this.cvParsingDone = true;
					this.$emit('parsing-done');
				}, 1500);
			}
		}
	},
	computed: {
		// calculate for intervals based on the thresholds for checkmarks
		checkmarkIntervals(): number[] {
			return this.calculateIntervals(this.checkmarkThresholds);
		},
		// calculate thresholds for percentage based on a fixed sequence
		percentageThresholds(): number[] {
			const thresholds: number[] = [];
			let sequenceIndex = 0;
			let intervalSum = 0;
			while (intervalSum < this.avgParsingTime) {
				const interval = (this.avgParsingTime / 7 / 10) * this.sequence[sequenceIndex];
				thresholds.push(interval);
				if (sequenceIndex >= this.sequence.length - 1) {
					sequenceIndex = 0;
				} else {
					sequenceIndex += 1;
				}
				intervalSum += interval;
			}
			return thresholds;
		},
		// calculate for intervals based on the thresholds for percentage
		percentageIntervals(): number[] {
			return this.calculateIntervals(this.percentageThresholds);
		},
		...mapStores(useUserProfileStore)
	},
	watch: {
		cvLoading: [
			{
				handler: 'onCvLoadingStateChange'
			}
		]
	},
	props: {
		cvLoading: { type: Boolean, default: false },
		cvParsed: { type: Boolean, default: false },
		avgParsingTime: { type: Number, default: 120 },
		checkmarkThresholds: {
			type: Array as PropType<number[]>,
			default: () => [14, 18, 16, 19, 17, 16, 20]
		}
	}
});
</script>
